import React, { useEffect, useState } from 'react'
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import { LiaRupeeSignSolid } from "react-icons/lia";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MoonLoader from "react-spinners/ClipLoader";
import { FaLock } from "react-icons/fa";
// import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'
const Permotion = () => {
    const [Loading, setLoading] = useState(false);
    const [rewards, setrewards] = useState(false);
    let history = useNavigate()
   
    useEffect(() => {
        if (!localStorage.getItem("user")) {
            history("/login")
        }
    }, [])
    function useWindowSize() {
        const [windowSize, setWindowSize] = useState({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      
        useEffect(() => {
          function handleResize() {
            setWindowSize({
              width: window.innerWidth,
              height: window.innerHeight,
            });
          }
      
          window.addEventListener('resize', handleResize);
      
          handleResize(); // Set initial size
      
          return () => window.removeEventListener('resize', handleResize);
        }, []);
      
        return windowSize;
      }
      const { width, height } = useWindowSize();
    const apllytobalance = async () => {
        if (JSON.parse(localStorage.getItem("user")).bonus <= 0) {
            toast.info('Bonus not enough!', {
                position: "top-left",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,

            });
            setLoading(false)
        } else {
            setLoading(true)
            const response = await fetch(`${process.env.REACT_APP_HOST}/api/game/applytobalance`, {
                method: 'POST',
                headers: {
                    'Content-type': "application/json",
                    "auth-token": localStorage.getItem("token")
                },

            });
            const json = await response.json()
            if (json.success) {
                const userorderdetails = async () => {
                    const response = await fetch(`${process.env.REACT_APP_HOST}/api/auth/getuser`, {
                        method: 'POST',
                        headers: {
                            'Content-type': "application/json",
                            "auth-token": localStorage.getItem("token")
                        },

                    });
                    const jsonx = await response.json()

                    if (jsonx.success) {

                        localStorage.setItem("user", JSON.stringify(jsonx.user))
                    }
                }
                setLoading(false)
                userorderdetails()
                toast.success('Apply to balance!', {
                    position: "top-left",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,

                });

            }
        }
    }
    return (
        <>
            <ToastContainer
                position="top-left"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover

            />
            <div className=' flex flex-col' >
                <div className="flex mb-5  text-2xl pt-4 pb-4 pl-2 pr-2 items-center" onClick={(() => {
                    if (!rewards) {
                        history(-1)
                    } else {
                        setrewards(false)
                    }
                })}>
                    {!rewards ?
                        <>
                            <div className="">

                                <IoMdArrowBack />
                            </div>
                            <div className=" mx-5">
                                <p>Promotion</p>
                            </div>
                        </>
                        : <>
                            <div className="">

                                <IoMdArrowBack />
                            </div>
                            <div className=" mx-5">
                                <p>Rewards</p>
                            </div>
                            <Confetti className=''
                                width={width}
                                height={height}
                                recycle={false}
                            />
                        </>}
                </div>
                {!rewards ? <>
                    <div className="flex mb-20 flex-col  items-center justify-center">
                        <div className="flex mb-5 text-xl items-center justify-center">

                            <p>Bonus : </p>
                            <LiaRupeeSignSolid className='text-2xl' />
                            <p>{JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")).bonus : ""}</p>
                        </div>
                        <button disabled={Loading} onClick={apllytobalance} className='border flex items-center gap-1 pr-7 pl-7 pt-2 pb-2 bg-blue-600 text-white rounded active:bg-blue-500'>
                            <MoonLoader color={"white"} loading={Loading} size={20} aria-label="CircleLoader" />
                            Apply to Balance</button>
                    </div>
                    <div className='w-full   '>
                        <div className="  flex items-center justify-between">

                            <div className="flex pl-5 mb-3 flex-col ">
                                <p className='text-slate-400 mb-2'>Total People</p>
                                <p className='ml-5'>{(JSON.parse(localStorage.getItem("user")).refered).length}</p>
                            </div>
                            <div className="mr-10 mb-5">

                                <button onClick={() => setrewards(true)} type="button" className="bg-gradient-to-r rounded-full shadow-xl text-white from-teal-400 to-blue-500 pt-2 pb-2 pl-7 pr-7">
                                    Rewards
                                </button>
                            </div>
                        </div>

                        <div className="flex pl-5  flex-col mb-3">
                            <p className='text-slate-400 mb-2'>My Promotion Code</p>
                            <p className='pb-1'>{JSON.parse(localStorage.getItem("user")).permotioncode}</p>
                            <hr />
                        </div>
                        <div className="flex pl-5 flex-col mb-10">
                            <p className='text-slate-400 mb-2'>My Promotion Link</p>
                            <p className=''>{`${process.env.REACT_APP_HOST_FRONTEND}/signup?referralCode=${JSON.parse(localStorage.getItem("user")).permotioncode}`}</p>
                            <hr />
                        </div>
                        <div className="flex  items-center  justify-center">
                            <button onClick={(() => {
                                navigator.clipboard.writeText(`${process.env.REACT_APP_HOST_FRONTEND}/signup?referralCode=${JSON.parse(localStorage.getItem("user")).permotioncode}`).then(() => {
                                    toast.success('Copy to clipboard!', {
                                        position: "top-left",
                                        autoClose: 1000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,

                                    });
                                })
                            })} className='border pr-7 pl-7 pt-2 pb-2 bg-slate-200 rounded active:bg-slate-50'>Copy Link</button>

                        </div>


                    </div>
                </> : <div className='mb-20 bg-slate-100 w-[100vw] pt-10 justify-center flex items-center flex-col'>
                    <div className="p-5 bg-gradient-to-r  overflow-auto text-white from-[#002423] vai-[#091d79] to-[#00d4ff] rounded-2xl border shadow-xl h-[35vh] mb-10 ">
                        <p className='font-bold '>Level 1 </p>
                        <li className='text-sm'>Refer 5 new users to our platform.</li>
                        <li className='text-sm'>Share your unique referral code.</li>
                        <li className='text-sm'>once five friends successfully register.</li>
                        <li className='text-sm'>you'll instantly receive ₹100 in your account.</li>
                        <li className='text-sm italic'> Start referring now and enjoy the rewards!</li>
                        <div className="justify-end flex  items-center mt-5 ">

                        <button className='pt-2  bg-white text-black pb-2 pl-5 pr-5 shadow-xl '>Claim</button>
                        </div>
                    </div>
                    <div className="p-5 w-[80vw] bg-gradient-to-r text-white from-[#000000] via-[#091c79] to-[#bc00ff]  rounded-2xl border shadow-xl h-[30vh] mb-10 ">
                        <p>Level 2 </p>
                        <p className='font-bold '>Level 1 </p>
                        <li className='text-sm'>Refer 5 new users to our platform.</li>
                        <li className='text-sm'>Share your unique referral code.</li>
                        <li className='text-sm'>once five friends successfully register.</li>
                        <li className='text-sm'>you'll instantly receive ₹100 in your account.</li>
                        <li className='text-sm italic'> Start referring now and enjoy the rewards!</li>
                        <div className="justify-end flex  items-center mt-5 ">

                        <button className='pt-2  bg-white text-black pb-2 pl-5 pr-5 shadow-xl '>Claim</button>
                        </div>
                        <FaLock className='text-[100px] m-auto ' />

                    </div>
                    <div className="p-5 w-[80vw] bg-gradient-to-r text-white from-[#020024] via-[#090979] to-[#00d4ff]  rounded-2xl border shadow-xl h-[30vh] mb-10 ">
                        <p>Level 3 </p>
                        <FaLock className='text-[100px] m-auto ' />

                    </div>
                    <div className="p-5 w-[80vw] bg-gradient-to-r text-white from-[#4b79a1] via-[#283e51] to-[#4b79a1]  rounded-2xl border shadow-xl h-[30vh] mb-10 ">
                        <p>Level 4 </p>
                        <FaLock className='text-[100px] m-auto ' />

                    </div>
                    <div className="p-5 w-[80vw] bg-gradient-to-r text-white from-[#13547a] via-[#80d0c7] to-[#13547a] rounded-2xl border shadow-xl h-[30vh] mb-10 ">
                        <p>Level 5 (Pro)</p>
                        <FaLock className='text-[100px] m-auto ' />

                    </div>


                </div>}
            </div>
        </>
    )
}

export default Permotion
