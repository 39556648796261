import React, { useEffect, useState } from 'react'
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import { LiaRupeeSignSolid } from "react-icons/lia";
import QRCode from 'qrcode.react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MdOutlineMenu } from "react-icons/md";
import { FaAngleDown } from "react-icons/fa";
import { RiSecurePaymentFill } from "react-icons/ri";
import MoonLoader from "react-spinners/ClipLoader";

const Recharge = () => {
  const history = useNavigate()
  const [Loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showdetails, setshowdetails] = useState(false);
  const [UTR, SetUTR] = useState("")
  const [Recharges, SetRecharges] = useState([])
  const [selectedElement, setSelectedElement] = useState(null);

  const [RechargeDetails, SetRechargeDetails] = useState(false)
  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };
  useEffect(() => {
    if (selectedOption == "") {
      setSelectedOption(null)
    }
  }, [selectedOption])
  const upiID = process.env.REACT_APP_UPI_ID;
  const payeeName = process.env.REACT_APP_PAYEE_NAME;
  const transactionNote = process.env.REACT_APP_TRANSACTION_NOTE;
  const generateUPIUrl = () => {
    return `upi://pay?pa=${upiID}&pn=${encodeURIComponent(payeeName)}&am=${selectedOption}&tn=${encodeURIComponent(transactionNote)}&cu=INR`;
  };
  const recharge = async (e) => {
    e.preventDefault()
    if (selectedOption < 100) {
      toast.info("Please enter amount above 100", {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,

      });
    }
    else {
      setshowdetails(true)

    }
  }
  const getrechargs = async () => {
    const response = await fetch(`${process.env.REACT_APP_HOST}/api/game/rechargedetails`, {
      method: 'GET',
      headers: {
        'Content-type': "application/json",
        'auth-token': localStorage.getItem("token")
      },
    });
    const json = await response.json()
    let x = json.reverse()
    let newArray = x.slice(0, 10);
    SetRecharges(newArray)

  }
  useEffect(() => {
    let check = true;
    if (check) {
      getrechargs()
      check = false;
    }
  }, [])
  const addrecharge = async (e) => {
    e.preventDefault()
    setLoading(true)
    if ((UTR.length) != 12) {
      toast.info("Please enter 12 digit UTR", {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,

      });
      setLoading(false)
    }
    else {
      try {
        const response = await fetch(`${process.env.REACT_APP_HOST}/api/game/addrecharge`, {
          method: 'POST',
          headers: {
            'Content-type': "application/json",
            'auth-token': localStorage.getItem("token")
          },
          body: JSON.stringify({ utr: parseInt(UTR), amount: selectedOption })
        });
        const json = await response.json()
        if (json.success) {

          toast.success("Submit Successfully", {
            position: "top-left",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            
          });
          setLoading(false)
          getrechargs()
          setTimeout(() => {
            setshowdetails(null);
            SetRechargeDetails(true)

          }, 2000);

        } else {
          toast.error(json.error, {
            position: "top-left",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,

          });
        }
      } catch (error) {
        toast.error(error, {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,

        });
      }
    }
  }

  const handleClick = (element) => {
    setSelectedElement((prevSelectedElement) =>
      prevSelectedElement === element ? null : element
    );

  };
  return (
    <div className='mb-20 md:mb-0 h-100vh'>
      <ToastContainer
        position="top-left"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover

      />
      <div className="  pb-5">
        {showdetails && <div className=' sticky top-0 text-white bg-blue-600 w-full  p-3 flex gap-2 items-center justify-between  font-bold  text-2xl'>
          <div className=" flex items-center justify-between" onClick={(() => {
            if (showdetails) {
              setshowdetails(false)
              setSelectedOption(null)
            } else {
              history(-1)
            }
          })}>
            <div className="cursor-pointer">

              <IoMdArrowBack />
            </div>
            <div className="cursor-pointer ml-2 ">
              <p className='cursore-pointer'>Payment</p>
            </div>
          </div>
          <div className="flex gap-2  items-center justify-between font-extrabold">

            <RiSecurePaymentFill className='text-green-500  text-3xl font-extrabold' />
            <p className='text-xl'>100% Secure</p>
          </div>
        </div>}
        {showdetails &&
          <div className=" text-white rounded-b-[35px] bg-blue-500  text-2xl pt-12 pb-12 pl-2 pr-2 items-center"></div>}

        {!showdetails && <div className="flex  justify-between   text-2xl p-3 items-center" >
          <div className="cursor-pointer flex items-center" onClick={(() => {
            if (showdetails) {
              setshowdetails(false)
              setSelectedOption(null)
            }
            else if (RechargeDetails) {
              SetRechargeDetails(false)
              setshowdetails(false)

            }
            else {
              history(-1)
            }
          })}>

            <IoMdArrowBack />
            <p className='cursore-pointer mx-5'>Recharge</p>
          </div>
          {!RechargeDetails &&

            <div className="flex text-2xl cursor-pointer pt-4 pb-4 pl-2 pr-2 md:pl-4 pr-4  items-center ">
              <MdOutlineMenu onClick={(() => {
                SetRechargeDetails(true)
                setshowdetails(null)
              })} />
            </div>}
        </div>}
        {showdetails && <div className="flex shadow-xl m-auto -mt-10 bg-white gap-2 rounded-lg border   pt-7 w-[90%]  pb-7  text-xl font-bold items-center justify-center">
          <p className=''>Amount Payable </p>
          <p className='flex text-blue-700  items-center justify-center'><LiaRupeeSignSolid className=' font-black text-2xl' />{selectedOption}.00 </p>
        </div>}
      </div>
      {showdetails == false &&
        <form onSubmit={recharge}>
          <div className='mb-10 '>
            <div className="flex mb-20  text-xl items-center justify-center">
              <p>Balance : </p>
              <LiaRupeeSignSolid className='text-2xl' />
              <p>{JSON.parse(localStorage.getItem("user")).balance}</p>
            </div>
            <div className="  flex  justify-center items-center">
              <input required type="number" onChange={((e) => setSelectedOption(e.target.value))} value={selectedOption} className='pt-3 pb-3 pl-5 w-[80%] md:w-[40%] rounded-full border' placeholder='Enter or select amount' />
            </div>
            {parseInt(selectedOption) < 100 ? <div className=" text-center mt-5">
              <p className='text-slate-400'>Minimum recharge 100</p>
            </div> : ""}
          </div>
          <div className="flex  justify-center w-[90%] m-auto  items-center mb-5 items-center  flex-row  justify-evenly">
            <input readOnly type="radio" id="contract1" name="hosting" value={100} className="hidden peer" required onChange={handleOptionChange} />
            <label htmlFor="contract1" className={`inline-flex items-center justify-center pt-2 pb-2   w-full  text-gray-500 bg-white border border-gray-200  cursor-pointer  ${selectedOption == 100 ? 'peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600' : 'hover:text-gray-600 hover:bg-gray-100 '}`}>
              <div className="block">
                <div className="w-full text-lg font-semibold">100</div>
              </div>

            </label>
            <input readOnly type="radio" id="contract2" name="hosting" value={1000} className="hidden peer" onChange={handleOptionChange} />
            <label htmlFor="contract2" className={`inline-flex items-center justify-center pt-2 pb-2   w-full  text-gray-500 bg-white border border-gray-200  cursor-pointer  ${selectedOption == 1000 ? 'peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600' : 'hover:text-gray-600 hover:bg-gray-100 '}`}>
              <div className="block">
                <div className="w-full text-lg font-semibold">1000</div>
              </div>

            </label>
            <input readOnly type="radio" id="contract3" name="hosting" value={10000} className="hidden peer" onChange={handleOptionChange} />
            <label htmlFor="contract3" className={`inline-flex items-center justify-center pt-2 pb-2   w-full  text-gray-500 bg-white border border-gray-200  cursor-pointer  ${selectedOption == 10000 ? 'peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600' : 'hover:text-gray-600 hover:bg-gray-100 '}`}>
              <div className="block">
                <div className="w-full text-lg font-semibold">10000</div>
              </div>

            </label>
            <input readOnly type="radio" id="contract4" name="hosting" value={100000} className="hidden peer" onChange={handleOptionChange} />
            <label htmlFor="contract4" className={`inline-flex items-center justify-center pt-2 pb-2   w-full  text-gray-500 bg-white border border-gray-200  cursor-pointer  ${selectedOption == 100000 ? 'peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600' : 'hover:text-gray-600 hover:bg-gray-100 '}`}>
              <div className="block">
                <div className="w-full text-lg font-semibold">100000</div>
              </div>

            </label>
          </div>
          <div className="flex justify-center  mt-10">
            <button type='submit' className='pt-3 pb-3 pl-20 pr-20 text-white bg-blue-500 font-bold text-xl shadow  border rounded-xl ' >Recharge</button>
          </div>
        </form>}
      {showdetails &&
        <form onSubmit={addrecharge} className='flex flex-col items-center justify-center '>
          
          <p className=' text-xl mb-5 '>Scan to Pay</p>

          <QRCode className='mb-5' value={generateUPIUrl()} size={230} />
          <p className='w-[80%] text-center mb-5 font-bold'>If Use PhonePe Scan QR code to Pay , You must copy the 12-digit UTR , Then Paste and Submit</p>
          <div className="flex items-center gap-2 mb-10">

            <p className='flex gap-2 font-bold md:text-xl'>UPI ID : <p className='text-blue-500 '>{upiID}</p></p>
            <button type='button' onClick={(() => {
              navigator.clipboard.writeText(upiID).then(() => {
                toast.success('Copy to clipboard!', {
                  position: "top-left",
                  autoClose: 1000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,

                });
              })
            })} className='bg-slate-200 shadow rounded pl-5 pr-5 pt-2 pb-2 active:bg-slate-100'>Copy</button>
          </div>
          <div className="flex items-center mb-10 justify-center gap-2 pl-3 pr-3 pt-3 pb-3 pl-5 border shadow-xl rounded-xl md:text-xl  font-bold">
            <p className='  md:text-xl font-bold '>UTR :</p>
            <input onChange={((e) => { SetUTR(e.target.value) })} maxLength={5} required type="number" className='placeholder:text-blue-500 ring-inset   focus:ring-2 focus:ring-inset focus:ring-blue-600 p-3 ' placeholder='Input 12-digit here' />
          </div>
          <button disabled={Loading} type='submit' className='pl-10 pr-10 mb-10 flex justify-center  items-center gap-1 w-[70%] md:w-[50%] font-bold md:text-xl text-white bg-blue-600  shadow pt-3 pb-3 '>
          <MoonLoader color={"white"} loading={Loading} size={20} aria-label="CircleLoader" />Submit</button>
          <div className="bg-blue-500 w-full  rounded-t-2xl flex items-center  justify-center">
            <p className='lex items-center gap-2 md:text-xl  text-white flex pt-7 md:pt-5 md:pb-5 pb-7 pr-3 pl-3'>
              <RiSecurePaymentFill className='text-green-500  text-3xl font-extrabold' /> Dear Member 100% Secure Payment Powered</p>
          </div>
        </form>}
      {RechargeDetails &&
        <div className="h-[80vh] border  z-10 overflow-auto  records-container pb-3 pr-3 pl-3 m-auto w-full md:w-[60%]">
          <div className="left-0  sticky top-0 bg-white pb-2  flex items-center justify-center mb-5">
            <p className='text-xl cursor-pointer mt-2 '>My Recharges</p>
          </div>
          {Recharges.map((element) => {
            return <div className="" key={element._id} onClick={() => {
              handleClick(element)
            }}>
              <div className="flex  pt-2 pb-2 pr-1 pl-1 border  items-center  ">

                <div className=" flex w-[100%] items-center  justify-between ">

                  <div className=""><p>{element.amount}</p></div>
                  <div className={`flex items-center  justify-between ${element.status == "Wait" ? "text-yellow-500 " : ""}${element.status == "Success" ? "text-green-700 " : ""}${element.status == "Reject" ? "text-red-700 " : ""}  w-1/4`}><p>{element.status}</p><FaAngleDown className='text-slate-400 hover:transition hover:duration-300 hover:rotate-180' /></div>
                </div>

              </div>
              {selectedElement === element && (
                <div className=" mb-2 mt-2 pl-2">
                  <div className="flex p-0.5 items-center  justify-between ">
                    <p className=' w-1/2 '>Amount</p>
                    <p className=' w-1/2'>{element.amount}</p>
                  </div>
                  <div className="flex  p-0.5 items-center  justify-between ">
                    <p className=' w-1/2'>UTR</p>
                    <p className=' w-1/2'>{element.UTR}</p>
                  </div>
                  <div className="flex  p-0.5 items-center  justify-between ">
                    <p className=' w-1/2'>Status</p>
                    <div className={`${element.status == "Wait" ? "text-purple-700 " : ""}${element.status == "Success" ? "text-green-700 " : ""}${element.status == "Reject" ? "text-red-700 " : ""}  w-1/2`}><p>{element.status}</p></div>

                  </div>
                  <div className="flex  p-0.5 items-center  justify-between ">
                    <p className=' w-1/2'>Created At </p>
                    <div className="w-1/2"><p>{new Date(element.createdAt).toLocaleString('en-GB', { year: 'numeric', day: '2-digit', month: '2-digit', hour: '2-digit', minute: '2-digit' }).replace(',', '')}</p></div>

                  </div>

                </div>)}

            </div>
          })}

        </div>
      }
    </div>
  )
}

export default Recharge
