import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { LiaRupeeSignSolid } from "react-icons/lia";
import { IoMdArrowBack, } from "react-icons/io";
import { MdOutlineMenu } from "react-icons/md";
import { ToastContainer, toast } from 'react-toastify';
import { FaAngleDown } from "react-icons/fa";
import 'react-toastify/dist/ReactToastify.css';
import MoonLoader from "react-spinners/ClipLoader";

const Withdrawal = () => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedElement, setSelectedElement] = useState(null);
    const [WithdrawalInfo, setWithdrawalInfo] = useState(false)
    const [Loading, setLoading] = useState(false);

    const history = useNavigate()
    let b = JSON.parse(localStorage.getItem("user")).balance;
    const [withdrawaldetails, Setwithdrawald] = useState([])
    const withdrawals = async () => {
        const response = await fetch(`${process.env.REACT_APP_HOST}/api/game/withdrawaldetails`, {
            method: 'GET',
            headers: {
                'Content-type': "application/json",
                'auth-token': localStorage.getItem("token")
            },
        });
        const json = await response.json()
        let x = json.reverse()
        let newArray = x.slice(0, 10);
        Setwithdrawald(newArray)

    }
    useEffect(() => {
        let check = true;
        if (check) {
            withdrawals()
            check = false;
        }
    }, [])
    const placewithdrawal = async () => {
        setLoading(true)
        if (JSON.parse(localStorage.getItem("user")).upi) {



            const response = await fetch(`${process.env.REACT_APP_HOST}/api/game/withdrawal`, {
                method: 'POST',
                headers: {
                    'Content-type': "application/json",
                    'auth-token': localStorage.getItem("token")
                },
                body: JSON.stringify({ ammount: selectedOption, upi: JSON.parse(localStorage.getItem("user")).upi })

            });
            const json = await response.json()
            let check = json.success;
            if (check) {
                check = false;
                setLoading(false)
                toast.success("Applied successfully", {
                    position: "top-left",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,

                });
                withdrawals()
                const userorderdetails = async () => {
                    const response = await fetch(`${process.env.REACT_APP_HOST}/api/auth/getuser`, {
                        method: 'POST',
                        headers: {
                            'Content-type': "application/json",
                            "auth-token": localStorage.getItem("token")
                        },
                    });
                    const json = await response.json()
                    if (json.success) {
                        localStorage.setItem("user", JSON.stringify(json.user))
                    }
                }
                userorderdetails()
                setWithdrawalInfo(true)
                setSelectedOption(null)
            } else {
                setLoading(false)
                toast.error(json.error, {
                    position: "top-left",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,

                });
            }
        }
        else {
            setLoading(false)
            toast.info("Please add bank card", {
                position: "top-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,

            });
        }
    }
    const handleClick = (element) => {
        setSelectedElement((prevSelectedElement) =>
            prevSelectedElement === element ? null : element
        );

    };
    return (
        <div>
            <ToastContainer
                position="top-left"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover

            />
            <div className="mb-10">
                <div className="flex  mb-10 justify-between" >
                    <div className="flex  text-2xl pt-4 pb-4 pl-2 pr-2 items-center ">

                        <div className="">

                            <IoMdArrowBack onClick={(() => {

                                if (WithdrawalInfo) {
                                    setWithdrawalInfo(false)
                                }
                                else {

                                    history(-1)
                                }

                            })} />
                        </div>
                        <div className=" mx-5  cursor-pointer" onClick={(() => {

                            if (WithdrawalInfo) {
                                setWithdrawalInfo(false)
                            }
                            else {

                                history(-1)
                            }

                        })}>
                            <p>{WithdrawalInfo ? "Withdrawal Info" : "Withdrawal"}</p>
                        </div>
                    </div>
                    {!WithdrawalInfo &&

                        <div className="flex text-2xl cursor-pointer pt-4 pb-4 pl-2 pr-2 md:pl-4 pr-4  items-center ">
                            <MdOutlineMenu onClick={(() => { setWithdrawalInfo(true) })} />
                        </div>}
                </div>
                {!WithdrawalInfo ? <>
                    <div className="flex mb-20 text-xl items-center justify-center">
                        <p>Balance : </p>
                        <LiaRupeeSignSolid className='text-2xl' />
                        <p>{b}</p>
                    </div>
                    <div className="flex mb-5 justify-center items-center">
                        <input type="number" onChange={((e) => setSelectedOption(e.target.value))} value={selectedOption} className='pt-3 pb-3 pl-5 w-[80%] md:w-[40%] rounded-full border' placeholder='Enter Withdrawal amount' />
                    </div>
                    <p className='text-center text-slate-400'>Fee : {selectedOption * 0.1} , Account : {b - (selectedOption)}</p>
                    {parseInt(selectedOption) < 250 ? <div className=" text-center mt-5">
                        <p>Minimum Withdrawal 250 </p>
                    </div> : ""}

                    <div className=" pt-2 pb-2 pl-2  m-auto w-[80%] md:w-[40%] pr-2  flex  items-center  mt-1">
                        <p className='text-center'>Bank details</p>
                    </div>
                    <div className={` pt-2 pb-2 pl-2   m-auto w-[80%] md:w-[40%] pr-2  ${JSON.parse(localStorage.getItem("user")).upi ? "border" : " justify-between  "} flex  items-center  mt-1`}>
                        <p className=' ml-5'>{JSON.parse(localStorage.getItem("user")).upi ? JSON.parse(localStorage.getItem("user")).upi : "Please add Bank details"}</p>

                        {!JSON.parse(localStorage.getItem("user")).upi &&
                            <button onClick={(() => {
                                history("/Bankcard")
                            })} className='bg-slate-100 text-sm shadow hover:bg-slate-300  rounded pt-2 pb-2 pr-5 pl-5 btn'>Add</button>
                        }
                    </div>
                    <div className="flex justify-center  mt-10">
                        <button

                            disabled={Loading}
                            onClick={(() => {
                                if (selectedOption != null && selectedOption >= 250) {

                                    let check = true;

                                    if (b < selectedOption) {
                                        toast.error("Balance not enough", {
                                            position: "top-left",
                                            autoClose: 2000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined,

                                        });
                                    } else {
                                        if (check) {
                                            placewithdrawal()
                                            check = false;
                                        }
                                    }
                                } else if (selectedOption == null) {
                                    toast.info("Please enter amount", {
                                        position: "top-left",
                                        autoClose: 2000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,

                                    });
                                } else if (selectedOption != null && selectedOption <= 250) {
                                    toast.info("Please enter amount above 250", {
                                        position: "top-left",
                                        autoClose: 2000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,

                                    });
                                }

                            })} className='pt-3 flex items-center gap-1 pb-3 pl-20 pr-20 text-white bg-blue-500 font-bold text-xl shadow border rounded-xl'>
                            <MoonLoader color={"white"} loading={Loading} size={20} aria-label="CircleLoader" />

                            Withdrawal</button>

                    </div>

                </> : <>

                    <div className="h-[80vh] border  z-10 overflow-auto  records-container pb-3 pr-3 pl-3 m-auto w-full md:w-[60%]">
                        <div className="left-0  sticky top-0 bg-white pb-2  flex items-center justify-center mb-5">
                            <p className='text-xl cursor-pointer mt-2 '>My Withdrawal</p>
                        </div>
                        {withdrawaldetails.map((element) => {
                            return <div className="text-sm" key={element._id} onClick={() => {
                                handleClick(element)
                            }}>
                                <div className="flex  p-2 border  items-center  ">

                                    <div className="flex  w-[100%] gap-0.1 flex-col ">
                                        <div className="w-[100%] flex items-center  justify-between ">

                                            <div className="font-bold"><p>{element.ammount}</p></div>
                                            <div className={`flex items-center font-bold justify-between ${element.status == "Applied" ? "text-purple-700 " : ""}${element.status == "Success" ? "text-green-700 " : ""}${element.status == "Reject" ? "text-red-700 " : ""}  w-1/4`}><p>{element.status}</p><FaAngleDown className='text-slate-400 hover:transition hover:duration-300 hover:rotate-180' /></div>
                                        </div>
                                        <div className="flex  p-0.5 items-center  justify-between ">
                                            <div className=""><p>{new Date(element.createdAt).toLocaleString('en-GB', { year: 'numeric', day: '2-digit', month: '2-digit', hour: '2-digit', minute: '2-digit' }).replace(',', '')}</p></div>

                                        </div>
                                    </div>

                                </div>
                                {selectedElement === element && (
                                    <div className=" mb-2 mt-2 pl-2">
                                        <div className="flex p-0.5 items-center  justify-between ">
                                            <p className=' w-1/2 '>Amount</p>
                                            <p className=' w-1/2'>{element.ammount}</p>
                                        </div>
                                        <div className="flex  p-0.5 items-center  justify-between ">
                                            <p className=' w-1/2'>Bank card</p>
                                            <p className=' w-1/2'>{element.upi}</p>
                                        </div>
                                        <div className="flex  p-0.5 items-center  justify-between ">
                                            <p className=' w-1/2'>Status</p>
                                            <div className={`${element.status == "Applied" ? "text-purple-700 " : ""}${element.status == "Success" ? "text-green-700 " : ""}${element.status == "Reject" ? "text-red-700 " : ""}  w-1/2`}><p>{element.status}</p></div>

                                        </div>
                                        <div className="flex  p-0.5 items-center  justify-between ">
                                            <p className=' w-1/2'>Created At </p>
                                            <div className="w-1/2"><p>{new Date(element.createdAt).toISOString().slice(0, 19).replace('T', ' ').replace(/-/g, '-').split(' ').map((part, index) => index === 0 ? part.split('-').reverse().join('-') : part.slice(0, 5)).join(' ')}</p></div>

                                        </div>

                                    </div>)}

                            </div>
                        })}

                    </div>
                    <div className='mb-20'>
                    </div>
                </>}
            </div>
        </div >
    )
}

export default Withdrawal
