import React, { useEffect, useState } from 'react'
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import { FaAngleDown } from "react-icons/fa";

const History = () => {
  const [selectedElement, setSelectedElement] = useState(null);
  const [myOrder, setmyorder] = useState([])
  useEffect(() => {
    const userorders = async () => {
      const response = await fetch(`${process.env.REACT_APP_HOST}/api/game/userorders`, {
        method: 'GET',
        headers: {
          'Content-type': "application/json",
          "auth-token": localStorage.getItem("token")

        },
      });
      const json = await response.json()
      let x = json.reverse()
      let newArray = x.slice(0, 15);
      setmyorder(newArray)
    }
    userorders()
  }, [])
  let history = useNavigate()

  const handleClick = (element) => {
    setSelectedElement((prevSelectedElement) =>
      prevSelectedElement === element ? null : element
    );

  };
  return (
    <div>
      <div className="flex mb-5  text-2xl pt-4 pb-4 pl-2 pr-2 items-center" onClick={(() => history(-1))}>
        <div className="">

          <IoMdArrowBack />
        </div>
        <div className=" mx-5">
          <p>History</p>
        </div>
      </div>
      <div className="h-[80vh] border pt-2 z-10 overflow-auto  records-container pb-3 pr-3 pl-3 m-auto w-full md:w-[60%]">
        <div className="left-0  sticky top-0 bg-white pb-2  flex items-center justify-center mb-5">
          <p className='text-xl cursor-pointer '>My Records</p>
        </div>
        {myOrder.map((element) => {
          return <div className="" key={element._id} onClick={() => {
            handleClick(element)
            // setshoworderdetails(!orderDetail)

          }}>
            <div className="flex  pt-2 pb-2 pr-1 pl-1 border  items-center  justify-between ">

              <div className=" flex w-[80%] items-center  justify-between ">

                <div className=""><p>{element.period}</p></div>
                <div className={`${element.status == "Wait" ? "text-yellow-500 " : ""} ${element.status == 'Loss' ? "text-red-500" : ""}${element.status == 'Success' ? "text-green-500" : ""}   w-1/6`}><p>{element.status}</p></div>
                {element.status != "Wait" && <div className={` ${element.status == "Wait" ? "text-yellow-500 " : element.status == "Success" ? "text-green-500 " : "text-red-500 "} w-1/6`}><p>{element.status == "Success" ? "+" : "-"}{element.status=="Success"?element.ammount:element.moneycontract}</p></div>}
              </div>
              <div className="">
                <FaAngleDown className='text-slate-400 hover:transition hover:duration-300 hover:rotate-180' />
              </div>
            </div>
            {selectedElement === element && (
              <div className=" mb-2 mt-2 pl-2">
                <div className="flex  p-0.5 items-center  justify-between ">
                  <p className=' w-1/2'>Period</p>
                  <p className=' w-1/2'>{element.period}</p>
                </div>
                <div className="flex  p-0.5 items-center  justify-between ">
                  <p className=' w-1/2'>Money Contract</p>
                  <p className=' w-1/2'>{element.moneycontract}</p>
                </div>
                <div className="flex  p-0.5 items-center  justify-between ">
                  <p className=' w-1/2'>Delivery</p>
                  <p className=' w-1/2'>{element.delivery}</p>
                </div>
                <div className="flex  p-0.5 items-center  justify-between ">
                  <p className=' w-1/2'>Fee</p>
                  <p className=' w-1/2'>{element.fee}</p>
                </div>
                {element.status !== "Wait" &&
                  <div className="flex  p-0.5 items-center  justify-between ">
                    <p className=' w-1/2'>Result</p>
                    <p className={`w-1/2 ${element.result ? "text-red-500" : "text-green-500"}`}>{element.result ? "red" : "green"}</p>
                  </div>
                }
                <div className="flex  p-0.5 items-center  justify-between ">
                  <p className=' w-1/2'>Select</p>
                  <p className={`w-1/2 ${element.color ? "text-red-500" : "text-green-500"}`}>{element.color ? "red" : "green"}</p>
                </div>
                <div className="flex  p-0.5 items-center  justify-between ">
                  <p className=' w-1/2'>Status</p>
                  <p className={`w-1/2 ${element.status == "Wait" ? "text-yellow-500 " : ""} ${element.status == 'Loss' ? "text-red-500" : ""}${element.status == 'Success' ? "text-green-500" : ""} `}>{element.status}</p>
                </div>
                {element.status !== "Wait" &&
                  <div className="flex  p-0.5 items-center  justify-between ">
                    <p className=' w-1/2'>Amount</p>
                    <p className={` w-1/2  ${element.status == 'Loss' ? "text-red-500" : ""}${element.status == 'Success' ? "text-green-500" : ""} `}>{element.status == "Success" ? "+" : ""}{element.ammount}</p>
                  </div>
                }
              </div>
            )}

          </div>
        })}

      </div>
    </div>
  )
}

export default History
