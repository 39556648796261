import Navbar from "./components/Navbar";
import ColorGame from "./components/ColorGame"
import Guess from "./components/Guess"
import Profile from "./components/Profile"
import { createBrowserRouter, RouterProvider, useNavigate } from 'react-router-dom'
import Recharge from "./components/Recharge";
import History from "./components/History";
import Login from "./components/Login";
import Signup from "./components/Signup";
import { useState ,useEffect} from "react";
import Withdrawal from "./components/Withdrawal";
import Bankcard from "./components/Bankcard";
import Forgot from "./components/Forgot";
import Permotion from "./components/Permotion";
import AIPredictions from "./components/AIPredictions";

function App() {
  
  useState(async()=>{
    if(!localStorage.getItem("token")){
    }
    else{
      const response = await fetch(`${process.env.REACT_APP_HOST}/api/auth/getuser`, {
        method: 'POST',
        headers: {
          'Content-type': "application/json",
          "auth-token":localStorage.getItem("token")
        },
        
      });
      const json = await response.json()

      if (json.success) {
       
        localStorage.setItem("user", JSON.stringify(json.user))
      }
     
    }
    
  },[])
  
  

const router = createBrowserRouter([
  {
    path: "/",
    element:<><Navbar/><ColorGame /></>
  },
  {
    path: "/Login",
    element:<><Navbar/> <Login/></>
  },
  {
    path: "/Signup",
    element:<><Navbar/> <Signup/></>
  },
  {
    path: "/Forgot",
    element:<><Navbar/> <Forgot/></>
  },
  {
    path: "/Guess",
    element:<><Navbar/><Guess/></>
  },
  {
    path: "/Profile",
    element:<><Navbar/> <Profile/></>
  },
  {
    path: "/Recharge",
    element:<><Navbar/> <Recharge/></>
  },
  {
    path: "/Permotion",
    element:<><Navbar/> <Permotion/></>
  },
  {
    path: "/Withdrawal",
    element:<><Navbar/> <Withdrawal/></>
  },
  {
    path: "/Bankcard",
    element:<><Navbar/> <Bankcard/></>
  },
  {
    path: "/History",
    element:<><Navbar/> <History/></>
  },
  {
    path: "/AIPredictions",
    element:<><Navbar/> <AIPredictions/></>
  },
])
return (
  <>
      <RouterProvider router={router}/>

    </>
  );
}

export default App;
