import React, { useEffect, useState } from 'react'
import { HiOutlineLightBulb } from "react-icons/hi";
import { FaInfoCircle } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { io } from 'socket.io-client';
import { useNavigate } from 'react-router-dom';

const AIPredictions = () => {
    const socket = io(process.env.REACT_APP_HOST, {
        transports: ['websocket'],
        withCredentials: true,
        extraHeaders: {
            'Access-Control-Allow-Origin': 'https://www.money2x.in'
        }
    });
    socket.on('connect_error', (error) => {
        console.log('Connection Error:', error);
    })
    const history = useNavigate()
    const [Showinfo, SetShowInfo] = useState(false);
    const [period, SetPeriod] = useState(0);
    const [predict, SetPredict] = useState(null);
    const [investment, Setinvestment] = useState(0);
    const [amount, Setamount] = useState(0);
    const [todayincome, Settodayincome] = useState(0);
    const [income, Setincome] = useState(0);
    const [oldresults, setOldResults] = useState([])
    const universalresults = async () => {
        const response = await fetch(`${process.env.REACT_APP_HOST}/api/game/allaipred`, {
            method: 'GET',
            headers: {
                'Content-type': "application/json",
                'auth-token': localStorage.getItem("token")

            },
        });
        const json = await response.json()
        Settodayincome(json.totalIncome)
        SetPredict(json.lastPrediction.predict)
        Setamount(json.lastPrediction.amount)
        Setinvestment(json.lastPrediction.investment)
        Setincome(json.lastPrediction.income)
        const reversedPredictions = [...json.predictions].reverse();
        setOldResults(reversedPredictions);


    }
    useEffect(() => {
        if (!localStorage.getItem("token")) {
            history("/Login")
        }
        else {

            socket.on("TimeUpdate", ({ currentTime, currentPeriod }) => {
                SetPeriod(currentPeriod)
            })

            let conditon = true;
            if (conditon) {
                universalresults()
                conditon = false
            }

        }
    }, [])
    useEffect(() => {
        universalresults()

    }, [period])
    return (
        <div className='flex items-center flex-col p-2'>
            {Showinfo &&
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-[90%] my-6 mx-auto max-w-3xl">
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <div className={` bg-slate-100 flex items-start justify-between p-5   rounded-t`}>
                                    <h3 className="text-2xl  ">
                                        AI Predictions
                                    </h3>
                                    <button onClick={(() => { SetShowInfo(false) })}>
                                        <MdCancel className='text-3xl ' />
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative  items-center justify-center flex p-5 flex-auto">
                                    <div className="">

                                        <p className='mb-2'>Our AI-driven prediction engine is designed to give you an edge in color predictions by analyzing 10,000 to 28,000 charts daily from previous games. With this vast data, our AI delivers highly accurate predictions six times a day: at 9 AM, 11:30 AM, 1 PM, 4:30 PM, 5:30 PM, and 8:30 PM.</p>
                                        <p>By following the AI’s predictions and using the 3x investment strategy—where you triple your bet after a loss—you increase your chances of consistent profit. The key is to have a sufficient balance to leverage this strategy. The more funds you have, the more you can earn. Track your daily income with AI predictions displayed at the top, and let the AI guide you to profit.</p>
                                    </div>
                                </div>
                                {/*footer*/}
                                <div className="flex items-center justify-end p-6 rounded ">
                                    <button onClick={(() => { SetShowInfo(false) })} className='bg-blue-700 text-white rounded pt-2 pb-2 pl-5 pr-5 '>OK</button>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40  bg-black"></div>
                </>

            }
            <div onClick={(() => { SetShowInfo(true) })} className="flex w-full mt-5 bg-slate-100 pl-10 pr-10 pt-3 pb-3  font-semibold items-center justify-between">
                <p className=' text-2xl flex gap-2 items-center'>AI Predictions <HiOutlineLightBulb /></p>
                <FaInfoCircle  className='text-2xl text-blue-700 cursor-pointer' />
            </div>
            <div className="border flex flex-col items-center bg-slate-100 w-full md:text-xl text-sm p-5 font-semibold mt-5 mb-5 ml-2 mr-2">
                <div className="flex mb-10 gap-2">
                    <p>Today Income  🤑</p>
                    <p className='text-green-500 text-center'>+{todayincome}</p>
                </div>
                <div className="flex w-full justify-between">
                    { income==0?  <>
                        <div className="flex gap-2 flex-col">

                            <p>Period : {period}</p>
                            <p className='flex items-center gap-2'>Predict Color : <p className={`${predict ? "text-red-500" : "text-green-500"}`}>{predict ? "Red" : "Green"}</p><p className={`${predict ? "bg-red-500" : "bg-green-500"} h-5 w-5 rounded-full `}></p></p>
                            <p>Investment : {amount}</p>

                        </div>
                        <div className="">
                            <p>Use Investment</p>
                            <p className='text-center text-2xl text-red-500'>{investment}x</p>
                        </div>

                    </>:
                    <div className='w-full flex justify-center'>
                    <p className='text-center'>Perdiction is Over . Next Prediction start soon</p>
                    </div>}
                </div>
            </div>
            <p className='text-center md:w-[60%] w-full pt-3 pb-3 md:text-xl text-sm  bg-green-500 font-semibold shadow-xl text-white'>Previous Predictions</p>
            <div className="h-[70vh] font-semibold mb-32 text-sm md:text-base overflow-auto border  result-container pb-3 w-full md:w-[60%]">
                <div className="flex pr-1 pl-1 cursor-pointer sticky top-0 bg-white  pt-2 pb-2  items-center justify-between ">
                    <div className=" w-1/2  text-center"><p>Period</p></div>
                    <div className=" w-1/3 text-center"><p>Color</p></div>
                    <div className=" w-1/2 text-center"><p>Amount</p></div>
                    <div className=" w-1/3 text-center "><p>Result</p></div>
                    <div className=" w-1/2 text-center"><p>Income</p></div>
                </div>

                {oldresults.map((m) => {
                    return <div key={m._id} className={`flex  border pr-1 pl-1 items-center  justify-between `}>
                        <div className=" w-1/2  pt-2 pb-2  text-center"><p>{m.period}</p></div>
                        <div className={` w-1/3 pt-2 pb-2    ${m.predict ? "bg-red-500" : "bg-green-500"} text-center text-white`}><p>{m.predict ? "Red" : "Green"}</p></div>
                        <div className=" w-1/3  pt-2 pb-2  text-center "><p>{m.amount}</p></div>
                        <div className=" w-1/3 pt-2 pb-2  text-center"><p>{m.result ? "Success" : "Loss"}</p></div>
                        <div className={`${m.result ? "text-green-500" : ""} w-1/2 pt-2 pb-2 text-center`}><p>{m.result ? "+" : ""}{m.income}</p></div>

                    </div>
                })}

            </div>
        </div>
    )
}

export default AIPredictions
