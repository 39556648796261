import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { IoMdArrowBack } from "react-icons/io";
import 'react-toastify/dist/ReactToastify.css';
import PhoneInput from 'react-phone-input-2';
import "react-phone-input-2/lib/style.css";
import { auth } from '../firebase.config';
import MoonLoader from "react-spinners/ClipLoader";
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';

const Forgot = () => {
    const [reset, setReset] = useState(true);
    const [user, setUser] = useState(null);
    const [otpLoading, setOtpLoading] = useState(false);
    const [passwordChangeLoading, setPasswordChangeLoading] = useState(false);
    const [otp, setOtp] = useState("");
    const [phone, setPhone] = useState("");
    const navigate = useNavigate();
    const [credentials, setCredentials] = useState({ email: "", password: "", cpassword: "", token: "" });

    const onChange = (e) => {
        setCredentials({ ...credentials, [e.target.name]: e.target.value });
    };

    const sendResetEmail = async () => {
        if (!phone) {
            toast.info('Enter Phone number!', {
                position: "top-left",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        setOtpLoading(true);

        try {
            const response = await fetch(`${process.env.REACT_APP_HOST}/api/auth/checkuser`, {
                method: 'POST',
                headers: { 'Content-Type': "application/json" },
                body: JSON.stringify({ email: phone })
            });

            const json = await response.json();

            if (json.success) {
                const recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {});
                try {
                    const confirmationResult = await signInWithPhoneNumber(auth, phone, recaptchaVerifier);
                    toast.success('OTP sent!', {
                        position: "top-left",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setUser(confirmationResult);
                } catch (error) {
                    toast.error('Error sending OTP. Please try again.', {
                        position: "top-left",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            } else {
                toast.error(json.error, {
                    position: "top-left",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            console.error(error);
            toast.error('An error occurred. Please try again.', {
                position: "top-left",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } finally {
            setOtpLoading(false);
        }
    };

    const verifyOtp = async (e) => {
        e.preventDefault();
        setPasswordChangeLoading(true);

        try {
            if (user) {
                const result = await user.confirm(otp);

                if (result) {
                    const response = await fetch(`${process.env.REACT_APP_HOST}/api/auth/change-password`, {
                        method: 'POST',
                        headers: { 'Content-Type': "application/json" },
                        body: JSON.stringify({ email: phone, password: credentials.password })
                    });

                    const json = await response.json();

                    if (json.success) {
                        toast.success('Password updated successfully', {
                            position: "top-left",
                            autoClose: 1000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });

                        setTimeout(() => {
                            navigate("/login");
                        }, 2000);
                    } else {
                        toast.error('Error updating password', {
                            position: "top-left",
                            autoClose: 1000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                } else {
                    toast.error("Invalid code", {
                        position: "top-left",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            }
        } catch (error) {
            console.error(error);
            console.log(user);
            toast.error('An error occurred. Please try again.', {
                position: "top-left",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } finally {
            setPasswordChangeLoading(false);
        }
    };

    return (
        <div>
            <ToastContainer
                position="top-left"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="flex mb-5 justify-between">
                <div className="flex mb-5 text-2xl pt-4 pb-4 pl-2 pr-2 items-center">
                    <div>
                        <IoMdArrowBack onClick={() => navigate(-1)} />
                    </div>
                    <div className="mx-5" onClick={() => navigate(-1)}>
                        <p>Forgot Password</p>
                    </div>
                </div>
            </div>
            <div className="h-screen">
                <h1 className='text-2xl text-center mb-5'>Forgot Password</h1>
                <div className="text-center mb-10">
                    {reset && <p>Or <Link to="/login" className='text-blue-700 underline'>Log in</Link></p>}
                </div>
                <form className='flex items-center flex-col justify-center' onSubmit={verifyOtp}>
                    <div className="mb-5">
                        <PhoneInput
                            country={"in"}
                            value={phone}
                            onChange={(e) => { setPhone("+" + e) }}
                            placeholder='Enter Phone Number'
                        />
                    </div>
                    <div id="recaptcha-container" className='mb-5'></div>
                    <div className="mb-5 flex justify-center gap-1 items-center">
                        <input
                            type="number"
                            className="form-control pr-5 pl-5 pt-2 pb-2 border"
                            onChange={(e) => setOtp(e.target.value)}
                            placeholder='Verification code'
                            required
                        />
                        <button
                            onClick={sendResetEmail}
                            type='button'
                            disabled={otpLoading}
                            className="border flex items-center gap-2 pl-5 pr-5 pb-2 pt-2 rounded bg-green-500 text-white"
                        >
                            <MoonLoader color={"white"} loading={otpLoading} size={20} aria-label="CircleLoader" />
                            OTP
                        </button>
                    </div>
                    <div className="mb-10 md:w-[20%] w-[80%]">
                        <input
                            type="password"
                            className="form-control w-full pr-5 pl-5 pt-2 pb-2 border"
                            name='password'
                            onChange={onChange}
                            placeholder='New Password'
                            required
                        />
                    </div>
                    <div>
                        <button
                            className="border flex items-center gap-2 pl-5 pr-5 pb-2 pt-2 bg-blue-600 text-white"
                            type="submit"
                        >
                            <MoonLoader color={"white"} loading={passwordChangeLoading} size={20} aria-label="CircleLoader" />
                            Change
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Forgot;
