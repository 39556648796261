import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { IoMdArrowBack, IoMdAdd } from "react-icons/io";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MoonLoader from "react-spinners/ClipLoader";

const Bankcard = () => {
    const history = useNavigate()
    const [showModal, setShowModal] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [credentials, setCredentials] = useState({ IFSC: "", accnum: "", Upi: "" });
    useEffect(() => {
        if (!localStorage.getItem("token")) {
            history("/Login")
        }

    }, [])

    const onChange = (e) => {
        setCredentials({ ...credentials, [e.target.name]: e.target.value })
    }
    const addBank = async (e) => {
        e.preventDefault();
        const { Upi } = credentials;
        setLoading(true)
        const response = await fetch(`${process.env.REACT_APP_HOST}/api/notes/addupi`, {
            method: 'POST',
            headers: {
                'Content-type': "application/json",
                "auth-token": localStorage.getItem("token")

            },

            body: JSON.stringify({ UPI: Upi })

        });
        const json = await response.json()
        if (json.success) {
            setLoading(false)
            toast.success(json.message, {
                position: "top-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,

            });
            const userorderdetails = async () => {
                const response = await fetch(`${process.env.REACT_APP_HOST}/api/auth/getuser`, {
                    method: 'POST',
                    headers: {
                        'Content-type': "application/json",
                        "auth-token": localStorage.getItem("token")
                    },

                });
                const json = await response.json()

                if (json.success) {

                    localStorage.setItem("user", JSON.stringify(json.user))
                    setShowModal(false)
                }
            }
            userorderdetails()
        }

    }

    return (
        <div>
            <ToastContainer
                position="top-left"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover

            />
            {showModal ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-[90%] my-6 mx-auto max-w-3xl">
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <div className={`flex  items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t`}>

                                    <label htmlFor="contract4" className={`inline-flex items-center justify-between w-full pt-3 pb-3 pl-5 pr-5 text-gray-500 bg-white    `}>
                                        <div className="block">
                                            <div className="w-full text-lg font-semibold">Enter UPI details</div>
                                        </div>

                                    </label>


                                </div>
                                <form action="#" onSubmit={addBank}>
                                    <div className="relative flex-col items-center justify-center flex p-5 flex-auto">


                                        <div className="flex mt-2 mb-2 flex-col">
                                            <label htmlFor="UPI">UPI ID</label>
                                            <input onChange={onChange} required type="text" className='border rounded mt-2 pt-2 pb-2 pl-3 pr-3' placeholder='Enter UPI Id' name="Upi" id="UPI" />
                                        </div>
                                    </div>

                                    <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded ">
                                        <button
                                            className="mx-5 bg-slate-100 pt-2 pb-2 pr-3 pl-3 rounded shadow"
                                            type="button"
                                            onClick={(() => {
                                                setShowModal(false)
                                                setLoading(false)
                                            })}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            className="bg-blue-500 flex items-center gap-1 pt-2 pb-2 pr-3 pl-3  rounded text-white font-bold shadow-xl "
                                            type="submit"
                                            disabled={Loading}
                                        >
                                            <MoonLoader color={"white"} loading={Loading} size={20} aria-label="CircleLoader" />
                                            Confirm
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40  bg-black"></div>

                </>
            ) : null
            }

            <div className="flex mb-5 justify-between" >
                <div className="flex mb-5 text-2xl pt-4 pb-4 pl-2 pr-2 items-center ">

                    <div className="">

                        <IoMdArrowBack onClick={(() => history(-1))} />
                    </div>
                    <div className=" mx-5 " onClick={(() => history(-1))}>
                        <p>Bank Card</p>
                    </div>
                </div>
                <div className="flex  cursor-pointer text-2xl pt-4 pb-4 pl-2 pr-5 items-center ">
                    <IoMdAdd onClick={(() => setShowModal(true))} />
                </div>
            </div>
            <div className="p-5 md:w-[70vw] m-auto">
                {JSON.parse(localStorage.getItem("user")).upi ?
                    <>
                        <div className="border pt-3 pb-3 pl-2 pr-2 rounded flex justify-between items-center mt-2">
                            <p className=''>{JSON.parse(localStorage.getItem("user")).upi}</p>
                        </div>

                    </>
                    :
                    <div className=" pt-3 pb-3 pl-2 pr-2 flex justify-center items-center mt-1">
                        <p className='text-center'>Please add Bank details</p>
                    </div>
                }
            </div>
        </div >
    )
}

export default Bankcard
