import React, { useEffect, useState } from 'react'
import Dashboard from './Dashboard'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import MoonLoader from "react-spinners/ClipLoader";

const Guess = () => {
  const [show, setshow] = useState(false)
  const [showcheckwin, setshowcheckwin] = useState(false)
  const [hint, sethint] = useState("")
  const [guess, setguess] = useState("")
  const [Loading, setLoading] = useState(false);
  const [chance, setchance] = useState(null)
  const [showModal, setshowModal] = useState(false)
  const history = useNavigate()
  useEffect(() => {
    if (!localStorage.getItem("user")) {
      history("/login")
    }
    localStorage.removeItem("gameid")
  }, [])
  const startgame = async () => {
    setLoading(true)
    const response = await fetch(`${process.env.REACT_APP_HOST}/api/game/start-game`, {
      method: 'POST',
      headers: {
        'Content-type': "application/json",
        'auth-token': localStorage.getItem("token")
      },
    });
    const json = await response.json()
    if (json.success) {
      localStorage.setItem("gameid", json.gameId);
      setLoading(false)
      setshowcheckwin(true)
      sethint(json.message)
      setchance(json.remainingChances)

    }else{
      toast.info(json.error, {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,

      });
      setLoading(false)
    }
  }
  const checkwin = async () => {
    if (guess == "") {
      toast.info("please enter guess", {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,

      });
      setLoading(false)
    }
    else {
      setLoading(true)

      const response = await fetch(`${process.env.REACT_APP_HOST}/api/game/makeguess/${localStorage.getItem("gameid")}`, {
        method: 'POST',
        headers: {
          'Content-type': "application/json",
          'auth-token': localStorage.getItem("token")
        },
        body: JSON.stringify({ guess: parseInt(guess) })

      });
      const json = await response.json()
      if (json.success) {
        setLoading(false)
        setchance(json.remainingChances)
        sethint(json.hint)
        if (json.isCorrect) {
          toast.success(json.message, {
            position: "top-left",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,

          });
          sethint(json.message)
          setshowModal(true)
        }

      }
      else {
        setLoading(false)
        toast.error(json.error, {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,

        });
        localStorage.removeItem("gameid")
        setshowcheckwin(false)
      }
    }
  }
  return (
    <> <ToastContainer
      position="top-left"
      autoClose={1000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover

    />
      <Dashboard />
      {showModal &&
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-[90%] my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className={`flex bg-green-500 text-white items-start  justify-between p-5 border-b border-solid border-blueGray-200 rounded-t`}>
                  <p className="text-2xl  ">
                    Congratulations !
                  </p>

                </div>
                {/*body*/}
                <div className="relative items-center justify-center flex p-5 flex-auto">
                  <div className="flex flex-col  justify-center">

                    <p >
                      {hint}
                    </p>
                    <p className='mt-2'>You earn {chance == 5 && "10000 rupee"}{chance == 4 && "50 rupee"}{chance == 3 && "20 rupee"}{chance == 2 && "5 rupee"}{chance == 1 && "2 rupee"}{chance <= 0 && "Nothing"}</p>
                  </div>



                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded ">
                  <button
                    className="mx-5 bg-slate-100 pt-2 pb-2 pr-3 pl-3 rounded shadow"
                    type="button"
                    onClick={(async () => {
                      const response = await fetch(`${process.env.REACT_APP_HOST}/api/auth/getuser`, {
                        method: 'POST',
                        headers: {
                          'Content-type': "application/json",
                          "auth-token": localStorage.getItem("token")
                        },

                      });
                      const json = await response.json()

                      if (json.success) {

                        localStorage.setItem("user", JSON.stringify(json.user))
                        setshowModal(false)
                        setshowcheckwin(false)
                        localStorage.removeItem("gameid")

                      }


                    })}
                  >
                    Ok
                  </button>

                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40  bg-black"></div>
        </>
      }
      <div className="mb-20   flex items-center" onClick={(() => { setshow(!show) })}>
        <div className="flex  shadow  flex-col pb-3 pt-2  m-auto pr-2 pl-2 md:pl-10 md:pr-10 md:pt-5 gap-5">
          <p className='text-2xl'>Game Rules and Details </p>
          <p className='text-sm'><strong>1. Objective:</strong> Guess the correct random number generated by the computer within 5 attempts.{!show ? <>..<button className='text-purple-700 font-bold' onClick={(() => setshow(true))}>Read More</button></> : ""}</p>
          {show ? <>
            <p className='text-sm '><strong>2. Number Range:</strong> The computer generates a random number between 1 and 100.</p>
            <p className='text-sm'><strong>3. Chances:</strong> You have 5 chances to guess the correct number.</p>
            <p className='text-sm'><strong>4. Hints:</strong> After each incorrect guess, you will receive a hint whether your guess is too high or too low.</p>
            <p className='text-sm'><strong>5. Rewards:</strong></p>
            <ul style={{ listStyle: "disc" }} className='text-sm pl-10'>
              <li className='pt-3'>If you guess the number correctly on the first attempt, you earn 10000 rupees.</li>
              <li className='pt-3'>If you guess the number correctly on the second attempt, you earn 50 rupees</li>
              <li className='pt-3'>if you guess the number correctly on the third attempt, you earn 20 rupees.</li>
              <li className='pt-3'>If you guess the number correctly on the fourth attempt, you earn 5 rupees</li>
              <li className='pt-3'>If you guess the number correctly on the fifth attempt, you earn 2 rupees.</li>

            </ul>
            <p className='text-sm mb-5'><strong>7. Balances:</strong> Your balance will be updated according to the reward earned after each successful guess.{show ? <> <button className='text-purple-700 font-bold' onClick={(() => setshow(false))}>Show less</button></> : ""}</p>
          </> : ""}
        </div>
      </div>
      <div className="flex flex-col items-center justify-center">

        {!showcheckwin && <button disabled={Loading} onClick={startgame} className='border mt-30 rounded flex items-center gap-1 bg-black text-white shadow  active:bg-slate-800 pl-10 pr-10 pt-2 pb-2'>
          <MoonLoader color={"white"} loading={Loading} size={20} aria-label="CircleLoader" />

          Start</button>}
        {showcheckwin &&
          <>
            <div className="flex md:w-[40%] w-full   mb-10 items-center flex-col">
              <p>If you win you will earn {chance == 5 && "10000 rupee"}{chance == 4 && "50 rupee"}{chance == 3 && "20 rupee"}{chance == 2 && "5 rupee"}{chance == 1 && "2 rupee"}{chance <= 0 && "Nothing"}</p>
              <div className=" items-center  pr-2 pl-2 w-full text-sm md:text-base mb-10 mt-10  flex justify-between">
                <p>{hint}</p>
                <p className='flex gap-2 '>Chance : <p className='text-purple-600 '>{chance<=0?0:chance}</p></p>
              </div>
              <div className='flex w-full p-1 gap-1   items-center justify-between'>
                <input type="number" onChange={((e) => { setguess(e.target.value) })} className='border w-full rounded pt-2 pb-2 pl-3 pr-7  ' placeholder="Enter you guess..." name="" id="" />
                <button disabled={Loading} onClick={checkwin} className='border mt-30 rounded w-[30%] md:w-[50%]  bg-green-500 text-white flex items-center justify-center gap-1 shadow  active:bg-slate-800 pt-2 pb-2'>
                  <MoonLoader color={"white"} loading={Loading} size={20} aria-label="CircleLoader" />

                  Check</button>
              </div>
            </div>
          </>}
      </div>
    </>
  )
}

export default Guess
